// React & Next
import { useMemo } from 'react';

// 3rd
import { Flex } from '@chakra-ui/react';
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  BarChart as ReChartsBarChart,
  Bar as ReChartsBar,
  Legend as ReChartsLegend,
  XAxis as ReChartsXAxis,
  YAxis as ReChartsYAxis,
} from 'recharts';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';

// App - Other
import { CircleIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';

type StackedBarEntryProps = {
  dataKey: string;
  color: string;
  name: string;
  onClick?: (isCtrlKeyPressed: boolean) => void;
};

type StackedBarProps = {
  data: object;
  stackedBars: StackedBarEntryProps[];
};

export const StackedBar = ({ data, stackedBars }: StackedBarProps) => {
  const normalizedData: object = useMemo(() => ({ ...data }), [data]);

  const isAllZeros = useMemo(() => {
    return Object.entries(data).every(([, entry]) => {
      return !entry;
    });
  }, [data]);

  const barsValuesMap = useMemo(() => {
    return stackedBars.map((bar) => {
      return !!normalizedData[bar.dataKey as keyof typeof normalizedData];
    });
  }, [normalizedData, stackedBars]);

  const firstIndexWithData = useMemo(() => {
    return barsValuesMap.indexOf(true);
  }, [barsValuesMap]);

  const lastIndexWithData = useMemo(() => {
    return barsValuesMap.lastIndexOf(true);
  }, [barsValuesMap]);

  return (
    <Flex w="100%" h="100%" position="relative">
      {isAllZeros ? (
        <Flex
          position="absolute"
          w="100%"
          h="35px"
          bg="linear-gradient(45deg, #ffffff 25%, #f5f7f9 25%, #f5f7f9 50%, #ffffff 50%, #ffffff 75%, #f5f7f9 75%, #f5f7f9 100%);"
          backgroundSize="22.63px 22.63px"
          borderRadius="4px"
        />
      ) : null}

      <ReChartsResponsiveContainer width="100%" height="100%">
        <ReChartsBarChart data={[normalizedData]} layout="vertical">
          <ReChartsXAxis type="number" hide={true} domain={['dataMin', 'dataMax']} />

          <ReChartsYAxis type="category" hide={true} />

          {stackedBars.map((bar, index) => {
            return (
              <ReChartsBar
                key={bar.dataKey}
                dataKey={bar.dataKey}
                fill={bar.color}
                radius={
                  index === firstIndexWithData && index === lastIndexWithData
                    ? [4, 4, 4, 4]
                    : index === lastIndexWithData
                      ? [0, 4, 4, 0]
                      : index === firstIndexWithData
                        ? [4, 0, 0, 4]
                        : [0, 0, 0, 0]
                }
                name={bar.name}
                hide={!normalizedData[bar.dataKey as keyof typeof normalizedData] as boolean}
                minPointSize={25}
                barSize={35}
                stackId="stacked"
                {...(bar.onClick ? { onClick: (e) => bar.onClick?.(e.metaKey || e.ctrlKey) } : {})}
                style={{
                  cursor: bar.onClick ? 'pointer' : 'default',
                }}
              />
            );
          })}

          <ReChartsLegend
            layout="horizontal"
            verticalAlign="bottom"
            align="left"
            content={(props) => <RenderLegend data={data} payload={props.payload || []} />}
          />
        </ReChartsBarChart>
      </ReChartsResponsiveContainer>
    </Flex>
  );
};

type RenderLegendProps = {
  data: object;
  payload: Payload[];
};

const RenderLegend = ({ data, payload }: RenderLegendProps) => {
  const legendText = (value: string) => {
    if (value && value.length) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return value;
  };

  return (
    <Flex gap="36px" mt="16px">
      {payload.map((entry, index) => {
        const { dataKey } = entry;
        const numericValue = data[dataKey as keyof typeof data];

        if (numericValue === undefined) {
          return null;
        }

        return (
          <Flex
            key={`item-${entry.value}-${index}`}
            alignItems="flex-start"
            gap="sm"
            onClick={(e) =>
              (entry.payload as { onClick?: (isCtrlKeyPressed: boolean) => void })?.onClick?.(
                e.metaKey || e.ctrlKey
              )
            }
            style={{
              cursor: (entry.payload as { onClick?: (isCtrlKeyPressed: boolean) => void })?.onClick
                ? 'pointer'
                : 'default',
            }}
          >
            <CircleIcon
              w="12px"
              h="12px"
              aria-label="Legend Circle Icon"
              zIndex={1}
              color={entry.color}
            />

            <Flex direction="column" gap="xs">
              <Text variant="small" fontWeight={500} textTransform="uppercase" color="text.primary">
                {legendText(entry.value)}
              </Text>

              <Text variant="body" fontWeight={700} color="text.primary">
                {numericValue}
              </Text>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};
