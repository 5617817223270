// 3rd
import { useToken } from '@chakra-ui/react';
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  RadialBarChart as ReChartsRadialBarChart,
  RadialBar as ReChartsRadialBar,
  PolarAngleAxis as ReChartsPolarAngleAxis,
} from 'recharts';

type GaugeProps = {
  value: number;
  color?: string;
  text?: string;
};

export const Gauge = ({ value, color, text }: GaugeProps) => {
  const [textSecondary, barDefaultColor] = useToken('colors', [
    'text.secondary',
    'surface.brand.primary',
  ]);

  return (
    <ReChartsResponsiveContainer width="100%" height="100%">
      <ReChartsRadialBarChart
        data={[{ value }]}
        innerRadius="100%"
        outerRadius="100%"
        barSize={6}
        barGap={4}
        startAngle={190}
        endAngle={-10}
        cy="68%"
      >
        <ReChartsPolarAngleAxis angleAxisId={1} type="number" domain={[0, 100]} tick={false} />

        <ReChartsRadialBar
          angleAxisId={1}
          dataKey="value"
          fill={color || barDefaultColor}
          cornerRadius={1}
          isAnimationActive={false}
          background
          label={
            text
              ? {
                  position: 'centerBottom',
                  fill: textSecondary,
                  fontSize: '10px',
                  fontWeight: 600,
                  textAnchor: 'middle',

                  formatter: () => text,
                }
              : undefined
          }
        />
      </ReChartsRadialBarChart>
    </ReChartsResponsiveContainer>
  );
};
