// React & Next
import { CSSProperties, ReactNode } from 'react';

// 3rd
import { Flex, type FlexProps } from '@chakra-ui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// App - Other
import { alpha } from '@/utils/color';
import { Text } from '@/components/atoms/typography';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler);

type LineChartProps = FlexProps & {
  count: number | ReactNode;
  chartLineColor?: string;
  chartStrokeColor?: string;
  withLineBackground?: boolean;
  chartStyles?: CSSProperties;
  textStyles?: CSSProperties;
};

export const LineChart = ({
  count,
  chartLineColor = '#334DFF',
  withLineBackground = true,
  chartStrokeColor,
  textStyles = {},
  chartStyles = {},
  ...props
}: LineChartProps) => {
  const randomData = generateAscendingNumbers(10);
  const anotherLineData = randomData.map((_) => _ + 15);
  const chartData: ChartData<'line'> = {
    labels: ['', '', '', '', '', '', ''],
    datasets: [
      {
        fill: true,
        tension: 0.5,
        data: randomData,
        borderColor: chartLineColor,
        backgroundColor: withLineBackground ? alpha(chartLineColor, 0.4) : 'transparent',
      },
    ],
  };

  if (chartStrokeColor) {
    chartData.datasets.push({
      fill: false,
      tension: 0.5,
      data: anotherLineData,
      borderColor: chartStrokeColor,
    });
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
  };

  return (
    <Flex direction="column" width="100%" position="relative" {...props}>
      {typeof count === 'number' ? (
        <Text position="absolute" zIndex={1} top={0} style={{ ...textStyles }}>
          {count}
        </Text>
      ) : (
        count
      )}

      <Line
        data={chartData}
        options={chartOptions}
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          minWidth: '100%',
          maxWidth: '100%',
          ...chartStyles,
        }}
      />
    </Flex>
  );
};

const generateAscendingNumbers = (
  count: number,
  start: number = 0,
  minIncrement: number = 1,
  maxIncrement: number = 15
) => {
  let lastNumber = start;
  return new Array(count).fill(0).map(() => {
    const increment = Math.floor(Math.random() * (maxIncrement - minIncrement + 1)) + minIncrement;
    lastNumber += increment;
    return lastNumber;
  });
};
