/* eslint-disable @typescript-eslint/no-explicit-any */
// 3rd
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  RadarChart as ReChartsRadarChart,
  Radar as ReChartsRadar,
  PolarGrid as ReChartsPolarGrid,
  PolarAngleAxis as ReChartsPolarAngleAxis,
  PolarRadiusAxis as ReChartsPolarRadiusAxis,
} from 'recharts';

type Radar = {
  dataKey: string;
  subjectKey: string;
};

type RadarProps = {
  data: any[];
  radar: Radar;
};

export const Radar = ({ data, radar }: RadarProps) => {
  return (
    <ReChartsResponsiveContainer width="100%" height="100%">
      <ReChartsRadarChart data={data} outerRadius="70%" startAngle={120} endAngle={-240}>
        <ReChartsPolarRadiusAxis
          domain={([dataMin, dataMax]) => {
            const absMax = Math.max(Math.abs(dataMin), Math.abs(dataMax));

            if (absMax < 1) {
              return [-0.2, 0.1];
            }

            return [dataMin * -0.1, absMax * 1.2];
          }}
          tickCount={4}
          tick={false}
          axisLine={false}
        />

        <ReChartsPolarGrid
          stroke="#CBD5E1"
          strokeOpacity={1}
          // strokeDasharray="5 5"
          filter="drop-shadow(10px 5px 40px #F1F5F9)"
          style={{
            fill: 'rgba(248, 250, 252, .3)',
          }}
        />

        <ReChartsPolarAngleAxis
          dataKey={radar.subjectKey}
          tick={(props) => (
            <CustomTick {...props} count={data[props.payload.index][radar.dataKey]} />
          )}
        />

        <ReChartsRadar
          dataKey={radar.dataKey}
          dot={{
            fill: '#17696D',
            r: 3.5,
            stroke: '#fff',
            strokeWidth: '2px',
            strokeLinejoin: 'round',
          }}
          stroke="#17696D"
          fill="color-mix(in srgb, #17696D 20%, transparent)"
        />
      </ReChartsRadarChart>
    </ReChartsResponsiveContainer>
  );
};

type CustomTickProps = {
  payload: any;
  x: number;
  y: number;
  textAnchor: 'start' | 'middle' | 'end' | 'inherit';
  count: number;
};

const CustomTick = ({ payload, x, y, textAnchor, count }: CustomTickProps) => {
  const titleY = payload.coordinate > 0 ? y - 14 : y + 4;
  const countY = payload.coordinate > 0 ? y + 2 : y + 18;

  return (
    <svg>
      <text
        x={x}
        y={titleY}
        textAnchor={textAnchor || 'middle'}
        fill="#8596AD"
        fontSize="10px"
        fontWeight={500}
      >
        {payload.value}
      </text>

      <text
        x={x}
        y={countY}
        textAnchor={textAnchor || 'middle'}
        fill="#475569"
        fontSize="12px"
        fontWeight={600}
      >
        {count}
      </text>
    </svg>
  );
};
