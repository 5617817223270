/* eslint-disable @typescript-eslint/no-explicit-any */
// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Circle, Flex, Skeleton } from '@chakra-ui/react';
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  AreaChart as ReChartsAreaChart,
  Area as ReChartsArea,
  Legend as ReChartsLegend,
} from 'recharts';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';

// App - Other
import { Text } from '@/components/atoms/typography';

type AreaProps = {
  dataKey: string;
  color: string;
  name: string;
  legendLabel?: ReactNode;
};

type AreasProps = {
  data: any[];
  areas: AreaProps[];
};

export const Areas = ({ data, areas }: AreasProps) => {
  return (
    <ReChartsResponsiveContainer width="100%" height="100%">
      <ReChartsAreaChart data={data} margin={{ top: 8, right: 8, left: 8 }}>
        {areas.map((area) => (
          <ReChartsArea
            key={area.dataKey}
            dataKey={area.dataKey}
            type="monotone"
            stroke={area.color}
            strokeWidth="2px"
            fill={`color-mix(in srgb, ${area.color} 20%, transparent)`}
            name={area.name}
            dot={{
              fill: `color-mix(in srgb, ${area.color} 20%, transparent)`,
              stroke: area.color,
              strokeWidth: 2,
              r: 4.5,
            }}
          />
        ))}

        <ReChartsLegend
          layout="horizontal"
          verticalAlign="bottom"
          align="left"
          content={(props) => {
            const payload = (props.payload || []).map((entry) => ({
              ...entry,
              payload: {
                ...entry.payload,
                label: areas.find((area) => area.dataKey === entry.dataKey)?.legendLabel,
              },
            }));

            return <RenderLegend payload={payload as PayloadEnriched[]} />;
          }}
          wrapperStyle={{
            paddingTop: '12px',
          }}
        />
      </ReChartsAreaChart>
    </ReChartsResponsiveContainer>
  );
};

const Loading = () => {
  return <Skeleton w="100%" h="100%" />;
};

Areas.Loading = Loading;

type PayloadEnriched = Payload & {
  payload: Payload['payload'] & { label?: ReactNode };
};

type RenderLegendProps = {
  payload: PayloadEnriched[];
};

const RenderLegend = ({ payload }: RenderLegendProps) => {
  const legendText = (value: string) => {
    if (value && value.length) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return value;
  };

  return (
    <Flex gap="md">
      {payload.map((entry, index) => (
        <Flex key={`item-${entry.value}-${index}`} alignItems="center" gap="6px">
          <Circle
            size="6px"
            aria-label="Legend Circle Icon"
            zIndex={1}
            bg={`color-mix(in srgb, ${entry.color} 15%, transparent)`}
            outline="1px solid"
            outlineColor={entry.color}
          />

          {entry.payload.label ? (
            entry.payload.label
          ) : (
            <Flex alignItems="flex-end" gap="3px">
              <Text variant="small" color="text.high-tone" fontWeight={500} lineHeight="18px">
                {legendText(entry.value)}
              </Text>
            </Flex>
          )}
        </Flex>
      ))}
    </Flex>
  );
};
