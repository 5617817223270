// React & Next
import { Inter } from 'next/font/google';

const inter = Inter({
  subsets: ['latin'],
});

export const fonts = {
  html: inter.style.fontFamily,
  header: inter.style.fontFamily,
  body: inter.style.fontFamily,
  mono: inter.style.fontFamily,
};
