/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/ban-ts-comment */
// React & Next
import { Fragment } from 'react';

// 3rd
import { Flex, useToken } from '@chakra-ui/react';
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  RadialBarChart as ReChartsRadialBarChart,
  RadialBar as ReChartsRadialBar,
  Legend as ReChartsLegend,
  PolarAngleAxis as ReChartsPolarAngleAxis,
} from 'recharts';
import type { Payload } from 'recharts/types/component/DefaultLegendContent';

// App - Other
import { CircleIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';

export type RadialBar = {
  dataKey: string;
  maxValue: number;
  color: string;
  name: string;
};

type RadialBarsProps = {
  data: any[];
  radialBars: RadialBar[];
  text?: string;
};

export const RadialBars = ({ data, radialBars, text }: RadialBarsProps) => {
  const [textPrimary] = useToken('colors', ['text.primary']);

  return (
    <ReChartsResponsiveContainer width="100%" height="100%">
      <ReChartsRadialBarChart
        data={data}
        innerRadius={75}
        outerRadius={Math.max(160, radialBars.length * 80)}
        barSize={8}
        barGap={4}
        startAngle={180}
        endAngle={0}
        cy="102%"
        cx="70%"
      >
        {radialBars.map((radialBar, index) => (
          <Fragment key={radialBar.dataKey}>
            <ReChartsPolarAngleAxis
              angleAxisId={index}
              type="number"
              domain={[0, radialBar.maxValue]}
              tick={false}
            />

            <ReChartsRadialBar
              angleAxisId={index}
              dataKey={radialBar.dataKey}
              fill={radialBar.color}
              name={radialBar.name}
              cornerRadius={6}
              forceCornerRadius={true}
              cornerIsExternal={true}
              background
              label={
                text && index === radialBars.length - 1
                  ? {
                      position: 'centerBottom',
                      fill: textPrimary,
                      fontSize: '24px',
                      fontWeight: 600,
                      textAnchor: 'middle',
                      dy: -10,

                      formatter: () => text,
                    }
                  : undefined
              }
            />
          </Fragment>
        ))}

        <ReChartsLegend
          layout="vertical"
          align="left"
          verticalAlign="bottom"
          content={(props) => {
            if (!props.payload || !props.payload.length) return null;

            const payload = props.payload.flatMap((item) => {
              // @ts-ignore
              if (item.payload['tooltipPayload'] && item.payload['tooltipPayload'].length) {
                return [
                  {
                    // @ts-ignore
                    ...item.payload['tooltipPayload'][0],
                    // @ts-ignore
                    value: item.payload['tooltipPayload'][0].name,
                  },
                ];
              }

              return [{}];
            });

            return <RenderLegend payload={payload || []} />;
          }}
          wrapperStyle={{
            width: '50%',
            paddingInlineStart: '5%',
            paddingInlineEnd: '2%',
          }}
        />
      </ReChartsRadialBarChart>
    </ReChartsResponsiveContainer>
  );
};

type RenderLegendProps = {
  payload: Payload[];
};

const RenderLegend = ({ payload }: RenderLegendProps) => {
  const legendText = (value: string) => {
    if (value && value.length) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return value;
  };

  return (
    <Flex direction="column">
      {payload.reverse().map((entry, index) => (
        <Flex key={`item-${entry.value}-${index}`} alignItems="center" gap="xs">
          <CircleIcon
            w="6px"
            h="6px"
            aria-label="Legend Circle Icon"
            zIndex={1}
            color={entry.color}
          />

          <Text variant="small">{legendText(entry.value)}</Text>
        </Flex>
      ))}
    </Flex>
  );
};
