// React & Next
import { useMemo } from 'react';
import type { CSSProperties } from 'react';

// 3rd
import { Flex, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  PieChart as ReChartsPieChart,
  Pie as ReChartsPie,
  Cell as ReChartsCell,
  Legend as ReChartsLegend,
} from 'recharts';
import type {
  HorizontalAlignmentType,
  Payload,
  VerticalAlignmentType,
} from 'recharts/types/component/DefaultLegendContent';
import type { LayoutType } from 'recharts/types/util/types';

// App - Other
import { CircleIcon } from '@/components/atoms/icon';
import { Text } from '@/components/atoms/typography';

type DoughnutSector = {
  name: string;
  value: number;
  color: string;
};

type DoughnutProps = {
  data: DoughnutSector[];
  cy?: number | string;
  legendProps?: {
    layout?: LayoutType;
    align?: HorizontalAlignmentType;
    verticalAlign?: VerticalAlignmentType;
    wrapperStyle?: CSSProperties;
    variant?: 'small' | 'body';
    color?: string;
  };
};

export const Doughnut = ({ data, cy, legendProps = {} }: DoughnutProps) => {
  const { variant = 'body', color = 'text.high-tone', ...restLegendProps } = legendProps;

  return (
    <ReChartsResponsiveContainer width="100%" height="85%">
      <ReChartsPieChart>
        <ReChartsPie
          data={data}
          dataKey="value"
          innerRadius="65%"
          outerRadius="100%"
          paddingAngle={2}
          cy={cy || '57%'}
        >
          {data.map((entry, index) => (
            <ReChartsCell key={`cell-${index}`} fill={entry.color} />
          ))}
        </ReChartsPie>

        <ReChartsLegend
          layout="vertical"
          verticalAlign="bottom"
          align="center"
          content={(props) => (
            <RenderLegend payload={props.payload || []} color={color} variant={variant} />
          )}
          wrapperStyle={{
            left: '0',
            top: '100%',
            minHeight: '60px',
          }}
          {...restLegendProps}
        />
      </ReChartsPieChart>
    </ReChartsResponsiveContainer>
  );
};

type RenderLegendProps = {
  payload: Payload[];
  variant: 'small' | 'body';
  color: string;
};

const RenderLegend = ({ payload, variant, color }: RenderLegendProps) => {
  const legendText = (value: string) => {
    if (value && value.length) {
      return value[0].toUpperCase() + value.substring(1);
    }

    return value;
  };

  return (
    <Flex direction="column">
      {payload.map((entry, index) => (
        <Flex key={`item-${entry.value}-${index}`} alignItems="center" gap="xs">
          <CircleIcon
            w="6px"
            h="6px"
            aria-label="Legend Circle Icon"
            zIndex={1}
            color={entry.color}
          />

          <Text variant={variant} color={color}>
            {entry.payload?.value !== undefined ? `${entry.payload?.value} ` : ''}
            {legendText(entry.value)}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

type LoadingProps = {
  size: number;
  noOfLegends?: number;
};

const Loading = ({ size, noOfLegends }: LoadingProps) => {
  const legends = useMemo(() => {
    if (noOfLegends === undefined || noOfLegends < 0) return Array.from({ length: 3 });

    return Array.from({ length: noOfLegends });
  }, [noOfLegends]);

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      h="100%"
      pb="8px"
    >
      <SkeletonCircle size={`${size}px`} mx="auto" mt="6px" />

      <Flex direction="column" gap="xs" minH="60px">
        {legends.map((_, index) => (
          <Flex key={index} alignItems="center" gap="xs">
            <SkeletonCircle size="6px" />

            <Skeleton height="18px" width="88px" />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

Doughnut.Loading = Loading;
