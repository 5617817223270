/* eslint-disable @typescript-eslint/no-explicit-any */
// 3rd
import {
  ResponsiveContainer as ReChartsResponsiveContainer,
  FunnelChart as ReChartsFunnelChart,
  Funnel as ReChartsFunnel,
  LabelList as ReChartsLabelList,
  Cell as ReChartsCell,
} from 'recharts';
import { Box } from '@chakra-ui/react';

type Funnel = {
  dataKey: string;
  subjectKey: string;
  color: string;
};

type FunnelProps = {
  data: any[];
  funnel: Funnel;
};

export const Funnel = ({ data, funnel }: FunnelProps) => {
  return (
    <Box position="relative" overflow="hidden" width="100%" height="100%">
      <ReChartsResponsiveContainer
        width="200%"
        height="100%"
        style={{
          position: 'absolute',
          left: '-100%',
        }}
      >
        <ReChartsFunnelChart margin={{ right: 50 }}>
          <ReChartsFunnel data={data} dataKey={funnel.dataKey} lastShapeType="rectangle">
            {data.map((entry, index) => (
              <ReChartsCell key={`cell-${index}`} fill={entry[funnel.color]} />
            ))}

            <ReChartsLabelList
              dataKey={funnel.subjectKey}
              position="right"
              stroke="none"
              fill="#475569"
              fontSize="12px"
              fontWeight={600}
              offset={14}
            />
          </ReChartsFunnel>
        </ReChartsFunnelChart>
      </ReChartsResponsiveContainer>
    </Box>
  );
};
