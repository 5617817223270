// React & Next
import type { ReactNode } from 'react';

// 3rd
import { Box } from '@chakra-ui/react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Background,
  Controls,
  ReactFlowProvider,
} from 'reactflow';
import type { Node, Edge, NodeTypes, EdgeTypes } from 'reactflow';
import 'reactflow/dist/style.css';

type FlowProps = {
  vertices: Node[];
  connections: Edge[];
  nodeExtensions?: NodeTypes;
  edgeExtensions?: EdgeTypes;
  children?: ReactNode;
};

export const Flow = ({
  vertices,
  connections,
  nodeExtensions,
  edgeExtensions,
  children,
}: FlowProps) => {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [nodes, setNodes, onNodesChange] = useNodesState(vertices);
  const [edges, setEdges, onEdgesChange] = useEdgesState(connections);

  return (
    <Box width="100%" height="100%">
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeExtensions}
          edgeTypes={edgeExtensions}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          proOptions={{ hideAttribution: true }}
          nodesDraggable={false}
          fitView
          minZoom={0.001}
        >
          <Background />

          <Controls showInteractive={false} />

          {children}
        </ReactFlow>
      </ReactFlowProvider>
    </Box>
  );
};
