// React & Next
import { useMemo, useRef, useEffect, useState } from 'react';

// 3rd
import { Bubble } from 'react-chartjs-2';
import 'chart.js/auto';
import type { LayoutPosition, Plugin, ChartOptions } from 'chart.js';

// App - Other
import Locale from '@/locale/en.json';
import { alpha } from '@/utils/color';
import { fonts } from '@/config/theme/foundations/fonts';

const locale = Locale.components.charts['priorities-bubble-chart'];

const sizes = {
  small: { baseFontSize: 19, baseRadius: 23, offset: { x: 1, y: 1 } },
  medium: { baseFontSize: 20, baseRadius: 30, offset: { x: 1, y: 0.9 } },
  large: { baseFontSize: 24, baseRadius: 40, offset: { x: 0.8, y: 0.8 } },
  extraLarge: { baseFontSize: 28, baseRadius: 50, offset: { x: 0.7, y: 0.7 } },
};

type PrioritiesBubbleChartProps = {
  highCount: number;
  mediumCount: number;
  lowCount: number;
  size?: 'small' | 'medium' | 'large' | 'extraLarge';
};

export const PrioritiesBubbleChart = ({
  highCount,
  mediumCount,
  lowCount,
  size = 'medium',
}: PrioritiesBubbleChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartSize, setChartSize] = useState({ width: 300, height: 150 }); // Default size
  const { baseFontSize, baseRadius, offset } = sizes[size] || sizes.medium;

  useEffect(() => {
    const handleResize = () => {
      if (chartRef.current) {
        setChartSize({
          width: chartRef.current.offsetWidth + 50,
          height: chartRef.current.offsetHeight + 50,
        });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const bubbles = useMemo(() => {
    // Font
    const highFontSize = baseFontSize;
    const mediumFontSize = baseFontSize * 0.8;
    const lowFontSize = baseFontSize * 0.6;

    // Size
    const highBubbleRadius = baseRadius;
    const mediumBubbleRadius = baseRadius * 0.85;
    const lowBubbleRadius = baseRadius * 0.7;

    // Position
    const center = { x: chartSize.width / 2, y: chartSize.height / 2 };
    const highX = chartSize.width - highBubbleRadius * offset.x;
    const highY = center.y + highBubbleRadius * offset.y * 2;
    const mediumX = highX - highBubbleRadius - mediumBubbleRadius * offset.x * 1.15;
    const mediumY = center.y - mediumBubbleRadius * offset.y;
    const lowX = highX + highBubbleRadius + lowBubbleRadius * offset.x * 1;
    const lowY = mediumY * 0.9;

    return [
      {
        x: highX,
        y: highY,
        r: highBubbleRadius,
        fontSize: highFontSize,
        textYOffset: 0,
        textXOffset: 0,
        label: locale['High'],
        backgroundColor: '#EC8C7E',
        value: highCount,
      },
      {
        x: mediumX,
        y: mediumY,
        r: mediumBubbleRadius,
        fontSize: mediumFontSize,
        textYOffset: 2,
        textXOffset: 3,
        label: locale['Medium'],
        backgroundColor: '#FFCA45',
        value: mediumCount,
      },
      {
        x: lowX,
        y: lowY,
        r: lowBubbleRadius,
        fontSize: lowFontSize,
        textYOffset: 2,
        textXOffset: -1,
        label: locale['Low'],
        backgroundColor: '#82B8FE',
        value: lowCount,
      },
    ];
  }, [
    baseFontSize,
    baseRadius,
    chartSize.width,
    chartSize.height,
    offset.x,
    offset.y,
    highCount,
    mediumCount,
    lowCount,
  ]);

  const data = {
    datasets: bubbles.map((bubble) => ({
      label: bubble.label,
      data: [{ x: bubble.x, y: bubble.y, r: bubble.r }],
      backgroundColor: bubble.backgroundColor,
      borderWidth: 5,
      borderColor: alpha(bubble.backgroundColor, 0.4),
      hoverBackgroundColor: bubble.backgroundColor,
    })),
  };

  const chartOptions: ChartOptions<'bubble'> = {
    animation: {
      duration: 300,
    },
    scales: {
      x: {
        display: false,
        min: 0,
        max: chartSize.width * 1.25,
        ticks: {
          stepSize: 10,
        },
      },
      y: {
        display: false,
        min: 0,
        max: chartSize.height * 1.25,
        ticks: {
          stepSize: 25,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'left' as LayoutPosition,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 5,
          useBorderRadius: true,
          borderRadius: 4,
          font: {
            size: 11,
            weight: 400,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  const bubblesTextPlugin: Plugin<'bubble'> = {
    id: 'bubblesTextPlugin',
    afterDatasetsDraw: function (chart) {
      const { ctx, data } = chart;

      data.datasets.forEach((dataset, i) => {
        const meta = chart.getDatasetMeta(i);

        if (!meta.hidden) {
          meta.data.forEach((element) => {
            const bubble = bubbles[i];

            ctx.fillStyle = 'rgb(255, 255, 255)';
            ctx.font = `500 ${bubble.fontSize}px ${fonts.body}`;

            const dataString = bubble.value.toString();

            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            const position = element.tooltipPosition(true);

            ctx.fillText(
              dataString,
              position.x - bubble.textXOffset,
              position.y + bubble.textYOffset
            );
          });
        }
      });
    },
  };

  return (
    <div ref={chartRef} style={{ width: '100%', height: '100%' }}>
      <Bubble redraw={true} data={data} options={chartOptions} plugins={[bubblesTextPlugin]} />
    </div>
  );
};
