// React & Next
import { useMemo } from 'react';

// 3rd
import { Box, Flex, Skeleton } from '@chakra-ui/react';

type ProgressBarsProps = {
  min?: number;
  max?: number;
  value: number;
  color?: string;
};

export const ProgressBars = ({ min = 0, max = 10, value, color }: ProgressBarsProps) => {
  const totalBars = useMemo(() => {
    return Array.from({ length: max - min }).map((_, i) => i + min);
  }, [max, min]);

  const coloredBars = useMemo(() => {
    return totalBars.map((i) => i < value);
  }, [totalBars, value]);

  return (
    <Flex gap="2px">
      {totalBars.map((_, i) => (
        <Box
          key={i}
          w="8px"
          h="14px"
          borderRadius="2px"
          bg={coloredBars[i] ? color || '#319795' : '#DDE6EE'}
        />
      ))}
    </Flex>
  );
};

type LoadingProps = {
  min?: number;
  max?: number;
};

const Loading = ({ min = 0, max = 10 }: LoadingProps) => {
  const totalBars = useMemo(() => {
    return Array.from({ length: max - min }).map((_, i) => i + min);
  }, [max, min]);

  return (
    <Flex gap="2px">
      {totalBars.map((_, i) => (
        <Skeleton key={i} w="8px" h="14px" borderRadius="2px" />
      ))}
    </Flex>
  );
};

ProgressBars.Loading = Loading;
